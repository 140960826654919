import React from "react";
import SiteMap from "containers/visuella/SiteMap";

const SiteMapPage = () => {
  return (
    <>
      <SiteMap />
    </>
  );
};
export default SiteMapPage;
